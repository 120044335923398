import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  showWhats:boolean=true;
  title = 'pomac-new';
  is_opened = false

  constructor(public translate: TranslateService){
          this.switchLanguage('ar')

      translate.setDefaultLang('ar');

    // if(localStorage.getItem('lang')){
    //   this.switchLanguage(localStorage.getItem('lang'))
    //   translate.setDefaultLang(localStorage.getItem('lang'));
    // }else{
    //   // set ar lang
    //   this.switchLanguage('ar')
    //   translate.setDefaultLang('ar');
    // }
  }
  ngOnInit(){

}
  switchLanguage(language: string) {

    this.translate.use(language);
    if (language !== 'ar' && document.getElementsByTagName('html')[0].hasAttribute('dir')) {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    } else if (language === 'ar' && !document.getElementsByTagName('html')[0].hasAttribute('dir')) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }
    localStorage.setItem('lang', language);
  }
  text:any=''
  url: any;

// send(){
// if(this.text==undefined){
//   return
// }else{
//   this.url=`https://api.whatsapp.com/send?phone=201094969284&text=${this.text}`
//   window.open(this.url,"_blank" , '')
//   this.text = ''
//   this.showWhats=false
// }
// }
sendDirect(){

    this.url=`https://api.whatsapp.com/send?phone=201094969284`
    window.open(this.url,"_blank" , '')



  }
}
