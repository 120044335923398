<section class="contact-us faq animate__animated animate__fadeIn" style="text-align: start;padding:150px 0px 50px;">
  <!-- <div class="banner">
    <div class="overlay text-center">
      <h1 class="fw-bold mb-3 "  style="  color: #fff;" data-aos="zoom-in"  data-aos-easing="ease-in-sine" data-aos-duration="600">
        الأسئلة الشائعة </h1>
        <p class=" mb-3" style="  color: #fff;" data-aos="zoom-in"  data-aos-easing="ease-in-sine" data-aos-duration="600">
          إذا لم تتمكن من العثور على السؤال، استخدم النموذج أعلاه للاتصال بنا. نحن مستعدون للمساعدة.
        </p>
    </div>
  </div> -->
  <div class="container ">

    <div class="head ">
      <h1 class="font-weight-bold "  data-aos="zoom-in"  data-aos-easing="ease-in-sine" data-aos-duration="600">
        الأسئلة الشائعة </h1>
        <p class=" mb-3" style="color: #1d1d1d !important;"  data-aos="zoom-in"  data-aos-easing="ease-in-sine" data-aos-duration="600">
          في هذا القسم تم حصر أكثر الأسئلة التي يحتاجها رواد الأعمال معرفتها  عن استوديو الشركات الناشئة بوماك وإذا لم تتمكن من العثور على السؤال، استخدم  نموذج اتصل بنا أسفل الصفحة ، ونحن مستعدون للمساعدة.
        </p>

  </div>
<div *ngIf="!show">
  <div class="ph-item" *ngFor="let item of  [].constructor(5); let i = index" >

    <div class="ph-col-12">

        <div class="ph-row">

            <div class="ph-col-12 big"></div>
        </div>
    </div>

</div>
</div>
    <div class="accordion my-5" id="accordionExample" *ngIf="show">
      <div class="card mb-3" *ngFor="let item of items; let i = index" data-aos="zoom-in"  data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <div class="card-header" id="heading{{i}}">
          <h5 (click)="!item.show?toggl(item):null"  data-toggle="collapse"
          attr.data-target="#collapse{{i}}" aria-expanded="true" attr.aria-controls="collapse{{i}}" class=" d-flex justify-content-between align-items-center m-0 w-100">
            <button   class="btn btn-link" type="button">
              {{item.question}}
            </button>
            <img [ngStyle]="item.show ? {'transform': 'rotate(180deg)', 'transform-origin': 'center'} : {}" src="assets/images/arrow_faq.svg" alt="">

          </h5>
        </div>

        <div id="collapse{{i}}" class=" collapse " [ngClass]="{ show: item.show }" attr.aria-labelledby="heading{{i}}"
          data-parent="#accordion">
          <div class="card-body">
            <p [innerHTML]=" item.answer">

            </p>
          </div>
        </div>
      </div>

    </div>
    <section class="idea">
      <div class="container">
        <h2>
          اذا لم تجد ما تريده
          <!-- -->
          <label data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
            يسعدنا تواصلك
           </label>



          <!-- تواصل معنا وأبدأ خطوات تنفيذ فكرتك -->
          للاجابة عن استفساراتك
          <!-- {{ translate.currentLang == 'ar' ? home_data.ques_ar : home_data.ques_en }}
          <label data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">{{
            translate.currentLang == 'ar' ? home_data.idea_ar : home_data.idea_en }} </label>
          <label data-aos="zoom-in-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">{{
            translate.currentLang == 'ar' ? home_data.mark_ar : home_data.mark_en }} </label>
          <br> {{ translate.currentLang == 'ar' ? home_data.talk_ar : home_data.talk_en }} -->
        </h2>
        <!-- <p class="text-secondary">

        </p> -->
        <a aria-labelledby="pomac" aria-label="pomac"  target="_blank" href="https://api.whatsapp.com/send?phone=201094969284">

          <button class="btn" data-aos="zoom-in-up" data-aos-offset="100" data-aos-easing="ease-in-sine"
            data-aos-duration="400">تواصل معنا</button>
        </a>
      </div>
    </section>
  </div>
</section>
