import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-datails',
  templateUrl: './blog-datails.component.html',
  styleUrls: ['./blog-datails.component.scss']
})
export class BlogDatailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
