<div class="ph-item" *ngIf="!show">

  <div class="ph-col-12">
      <div class="ph-picture"></div>
  </div>

</div>


<footer *ngIf="show&&setting">
    <div class="container">
        <div class="top row">
                <div class="social-media col-md-4">
                    <a aria-labelledby="pomac" aria-label="pomac" href="https://www.behance.net/pomac" *ngIf="setting?.behance" target="_blank">
                        <i class="fab fa-behance"></i>
                    </a>
                    <a aria-labelledby="pomac" aria-label="pomac" [href]="setting?.dribbble" *ngIf="setting?.dribbble" target="_blank">
                        <i class="fab fa-dribbble"></i>
                    </a>
                    <a aria-labelledby="pomac" aria-label="pomac" href="https://web.facebook.com/pomacstudio" *ngIf="setting?.facebook" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a aria-labelledby="pomac" aria-label="pomac" href="https://twitter.com/Pomac_studio" *ngIf="setting?.twitter" target="_blank">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a aria-labelledby="pomac" aria-label="pomac" href="https://www.linkedin.com/company/pomacstudio" *ngIf="setting?.linkedin" target="_blank">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </div>
                <div class="col-md-4 text-center mt-2 mb-2">
                    <img src="assets/images/adasda.svg" class="w-50 logo" alt="POMAC">
                </div>
                <div class="col-md-4 links">
                    <ul class="list-unstyled m-0 p-0 text-right">
                      <!-- [routerLink]="'/home'" [fragment]="'mainSlider'"  -->
                        <li><a aria-labelledby="pomac" aria-label="pomac" [routerLink]="['/home']">{{'HOME.home'| translate}}</a></li>
                        <!-- [routerLink]="'/home'" [fragment]="'projects'"  -->
                        <li><a aria-labelledby="pomac" aria-label="pomac"  [routerLink]="'/our-work'">
                          {{translate.currentLang == 'ar' ? 'أعمالنا' : 'Our Work'}}
                        </a></li>
                        <!-- [routerLink]="'/home'" [fragment]="'Services'" -->
                        <li><a aria-labelledby="pomac" aria-label="pomac" [routerLink]="'/our-services'" >{{'HOME.services'| translate}}</a></li>

                        <!-- <!-- <li><a aria-labelledby="pomac" aria-label="pomac" [routerLink]="['/about']" href="javascript:void(0)">{{'HOME.about'| translate}}</a></li> -->
                        <li ><a aria-labelledby="pomac" aria-label="pomac" [routerLink]="['/faq']" href="javascript:void(0)">الأسئلة الشائعة</a></li>
                    </ul>
                </div>
        </div>
        <!-- <div class="middle row pt-5">
            <div class="col-md-4">
                <img src="assets/images/footer/phone.svg" alt="POMAC">
                <h5><a aria-labelledby="pomac" aria-label="pomac" href="tel:{{setting?.phone}}">{{setting.phone}}</a> <br> <a aria-labelledby="pomac" aria-label="pomac" href="tel:{{setting.phone}}">{{setting.phone_2}}</a></h5>
            </div>
            <div class="col-md-4">
                <img src="assets/images/footer/email.svg" alt="POMAC">
                <h5>
                    href="mailto:{{setting.email}}"
                    <a aria-labelledby="pomac" aria-label="pomac" href="mailto:sales@pomac.info">
                    {{setting.email}}
                    sales@pomac.info

                </a> </h5>
            </div>
            <div class="col-md-4">
                <img src="assets/images/footer/location.svg" alt="POMAC">
                <h5> <a aria-labelledby="pomac" aria-label="pomac" target="_blank"
                   href="https://goo.gl/maps/seYWuz5WCVeTbccx5">{{setting.address}}</a></h5>
            </div>
        </div> -->
        <div class="bottom text-center">
            <p class="copyright font-weight-bold m-0">
              <!-- Copyright @ 2023 POMAC. All rights reserved. -->
              {{ translate.currentLang == 'ar' ?  'جميع الحقوق محفوظة @ 2024 لدى بوماك.'
               : 'Copyright @ 2023 POMAC. All rights reserved.' }}
               <br>
               <span style="font-size: 12px;">V 10.3.24</span>
            </p>
        </div>
    </div>
</footer>
